/* eslint-disable */
import React from "react"
import {graphql} from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import Offers from '../../components/common/Offers'
import CarbonContainer from "../../components/pages/CarbonPage";

class CarbonPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    var userLang = navigator.language || navigator.userLanguage;
    let redirect = sessionStorage.getItem("redirect");
    if(redirect !== "true"){
      if(userLang === 'fr-CA' || userLang === 'fr'){
        window.location.href = '/fr/carboneutre'
        sessionStorage.setItem("redirect", "true");
      }
    }

  }


  render() {
    const { data } = this.props
    let urls = {
      en: data.defaultPage.urls.en,
      fr: data.defaultPage.urls.fr
    }

    return (
      <Layout
        meta={data.defaultPage.meta}
        urls={urls}
        locale='en'
      >
        <Header
          urls={urls}
          locale='en'
        />
        <CarbonContainer locale='en' />
        <Footer
          cn
          extraRules={
          '<sup>†</sup>Royale<sup>®</sup> tissue products are manufactured by Irving Consumer Products Limited, an affiliate of J.D. Irving, Limited. J.D. Irving, Limited and affiliated corporations have planted a billion trees since 1957.' +
          '<br/><br/>*Each year, forests managed by J.D. Irving, Limited and affiliated corporations remove more carbon than is emitted in the lifecycle of Royale<sup>®</sup> tissue products. '
        } />
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  defaultPage(guid: { eq: "3ce73b99-42c8-4bf9-bd90-c0b6a48eaeae" }, locale: {eq:"en"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default CarbonPage
